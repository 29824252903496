import * as React from "react"
import Header from "../site/header/Header"
import Footer from "../site/footer/Footer"
import './layoutStyle.css'
import { Box } from "@mui/system"
const Layout = ({children}) =>{
return (
    <>
    <Header/>
    <Box component="main" px={8}>
    {children}
    </Box>
    <Footer/>
    </>
)
}
export default Layout
