import * as React from "react";
import { Stack, Box, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import Image from "../../../../images/logo.png"
const Logo = () => {
  return (
    <Link href={'/'} underline={"none"} color={'inherit'}>
    <Box
      sx={{
        backgroundColor:"#fff202",
        border:"solid white",
        borderRadius: "100%",
        height: 200,
        minHeight:200,
        width: 200,
        minWidth:200,
        overflow: "hidden",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          paddingLeft:'53px !important',
          paddingTop:'8px'
        }}
      >
        <Box
          component="img"
          sx={{
            width: "110px",
          }}
          alt="BusinessHamster Logo"
          src={Image}
        />
        <Typography sx={{fontSize:'0.6em',fontWeight:'bold',textDecoration:'none'}}>BUSINESSHAMSTER</Typography>
      </Stack>
    </Box>
      </Link>
  );
};
export default Logo;
