import * as React from "react"
import {Stack,Button} from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Logo from "./Logo";
import useMediaQuery from '@mui/material/useMediaQuery';
const Header = () => {
  return (
    <>
      {useMediaQuery('(min-width:600px)')&&<Button href={'/kontakt'} variant="contained" color={"error"}
               sx={{padding: '30px 24px 210px 24px', borderTop: '0', position: 'absolute', top: 0, right: '160px'}}>
        <span><MailOutlineIcon sx={{ml: '1rem'}}/><br/> Kontakt</span>

      </Button>}
    <Stack
    direction="row"
    component="header"
    alignItems= 'flex-start'
    spacing={2}
    sx={{mx:12,mt:3,mb:4}}
    >
      <Logo/>
    </Stack>
    </>
  );
};
export default Header;
