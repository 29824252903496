import * as React from "react"
import {Box} from "@mui/material";
import Navigation from "../header/Navigation"
const links=[
{    name:"Impressum",
    url:"/impressum"},
    {
        name:"Datenschutzerklärung",
        url:"/datenschutz"
    }
]
const Footer = () =>{
    return (<Box component="footer" sx={{mt:4,p:2,backgroundColor:"white", color:"black", borderRadius:"16px 16px 0 0", border:"solid"}}><Navigation links={links}/></Box>)
}
export default Footer
