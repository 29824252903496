import * as React from "react";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
const link = [
  {
    name: "Home",
    url: "/",
  },
  { name: "Kontakt", url: "/kontakt"}
];

const Navigation = ({links=link,sx}) => {
  return (
    <Stack component="nav" direction="row" spacing={2}>
      {links.map((link,id) => (
        <Link key={id} variant="body2" underline="none" sx={{fontWeight: "bold",...sx}} href={link.url}>{link.name}</Link>
      ))}
    </Stack>
  );
};
export default Navigation;
